import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/projectsTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The idea for this project came to me when COVID-19 was starting to appear in Europe. A lot of news where just bad news and I was wondering if I could use the `}<a parentName="p" {...{
        "href": "https://fabiorosado.dev/tweetnalytics"
      }}>{`Tweetnalytics`}</a>{` project to classify news headlines and filter the bad ones from the good ones. I've also taken the opportunity to use technology that I haven't used before and built the project on my Twitch channel - `}<a parentName="p" {...{
        "href": "https://twitch.tv/theflyingdev"
      }}>{`TheFlyingDev`}</a>{`.`}</p>
    <p>{`Building the project was fun. Not only did I solve a lot of problems along the way, but I've also learned a lot about Django, Nginx, Docker and Nextjs. To scrape the news headlines I am using the python library Scrappy. On the backend, I am also using the Django Rest Framework to create a REST API that I will open to the public in the future.`}</p>
    <p>{`This project evolved with time and there is still a lot of work to do. My main focus at the moment is working on the API and add authentication so users can register to the site and use the API on their projects. The second thing I would like to work is to choose the news websites better, some of the articles are hidden behind a paywall and that gives a bad user experience.`}</p>
    <h2 {...{
      "id": "tech-stack",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#tech-stack",
        "aria-label": "tech stack permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tech Stack`}</h2>
    <ul>
      <li parentName="ul">{`Django for the backend`}</li>
      <li parentName="ul">{`Nextjs for the frontend`}</li>
      <li parentName="ul">{`Nginx for server management`}</li>
      <li parentName="ul">{`certbot for ssl certificates`}</li>
      <li parentName="ul">{`Docker compose`}</li>
    </ul>
    <h2 {...{
      "id": "info",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#info",
        "aria-label": "info permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Info`}</h2>
    <p><strong parentName="p">{`Website:`}</strong>{` `}<a parentName="p" {...{
        "href": "https://thumbsupnews.net/"
      }}>{`https://thumbsupnews.net/`}</a></p>
    <p><strong parentName="p">{`GitHub repo:`}</strong>{` `}<a parentName="p" {...{
        "href": "https://github.com/FabioRosado/thumbsupnews"
      }}>{`Thumbsupnews Repository`}</a></p>
    <h2 {...{
      "id": "images",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#images",
        "aria-label": "images permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Images`}</h2>
    <p>{`Thumbsupnews Home Page
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/438029d35da98336637fba59d1c44764/03ffe/thumbsupnews_index.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "234.39999999999998%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAvABQDASIAAhEBAxEB/8QAGQAAAwEBAQAAAAAAAAAAAAAAAAIDAQQF/8QAFwEBAQEBAAAAAAAAAAAAAAAAAQACA//aAAwDAQACEAMQAAAB9o55x2kiIPK7tjS5ya2EDDv/xAAcEAACAgMBAQAAAAAAAAAAAAAAAQISESExQSL/2gAIAQEAAQUCsXFLJsjYjxSdvI8S+/I83lZEVKaUcH//xAAcEQACAgIDAAAAAAAAAAAAAAAAAQIREBIhMlH/2gAIAQMBAT8BT4LJ6vqU/RRrH//EABcRAAMBAAAAAAAAAAAAAAAAAAEQICH/2gAIAQIBAT8BQ2P/xAAcEAACAgIDAAAAAAAAAAAAAAAAMRARAQIgISL/2gAIAQEABj8CQoz6O9hlUIQ5QuP/xAAdEAADAQEAAgMAAAAAAAAAAAAAAREhMWFxQVGR/9oACAEBAAE/IVtiozBwt+wfepX2DWXrEoxbwHffXmmVrPIxKvv8Cy6n4LnCaTikUp//2gAMAwEAAgADAAAAEDTkv7sv/8QAGhEBAAIDAQAAAAAAAAAAAAAAAQBBEBEhcf/aAAgBAwEBPxAFLxKt8g2mFxgk2uOP/8QAGREAAgMBAAAAAAAAAAAAAAAAACEBEVFB/9oACAECAQE/EB4IKnSF0Wn/xAAdEAACAwEBAQEBAAAAAAAAAAABEQAhMUFhcYGh/9oACAEBAAE/ECAFd7AQxRKnLit44aXlA83yECqQRbV/I8nvsJiECBh+wxgQoUXdwzMCeBw4IjAEKeoMy64YIZGvrgZEF2/XsQBqH9fYs2AL5A8jrdRxZFcgzB08n//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Thumbsupnews Home Page",
            "title": "Thumbsupnews Home Page",
            "src": "/static/438029d35da98336637fba59d1c44764/953fe/thumbsupnews_index.jpg",
            "srcSet": ["/static/438029d35da98336637fba59d1c44764/20b44/thumbsupnews_index.jpg 125w", "/static/438029d35da98336637fba59d1c44764/bce2d/thumbsupnews_index.jpg 250w", "/static/438029d35da98336637fba59d1c44764/953fe/thumbsupnews_index.jpg 500w", "/static/438029d35da98336637fba59d1c44764/d0f75/thumbsupnews_index.jpg 750w", "/static/438029d35da98336637fba59d1c44764/0a251/thumbsupnews_index.jpg 1000w", "/static/438029d35da98336637fba59d1c44764/03ffe/thumbsupnews_index.jpg 1200w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Thumbsupnews Contact Page
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6ef46a9e7ccad0fca1626299f48c2b3e/03ffe/thumbsupnews_contact.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "80%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB35QUf//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEAAQUCX//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABkQAAIDAQAAAAAAAAAAAAAAAAEQABEhcf/aAAgBAQABPyHkGsCl/9oADAMBAAIAAwAAABBjD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABsQAAICAwEAAAAAAAAAAAAAAAERACEQMVFx/9oACAEBAAE/EEgUuEdgF7EIx2WLx//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Thumbsupnews Contact Page",
            "title": "Thumbsupnews Contact Page",
            "src": "/static/6ef46a9e7ccad0fca1626299f48c2b3e/953fe/thumbsupnews_contact.jpg",
            "srcSet": ["/static/6ef46a9e7ccad0fca1626299f48c2b3e/20b44/thumbsupnews_contact.jpg 125w", "/static/6ef46a9e7ccad0fca1626299f48c2b3e/bce2d/thumbsupnews_contact.jpg 250w", "/static/6ef46a9e7ccad0fca1626299f48c2b3e/953fe/thumbsupnews_contact.jpg 500w", "/static/6ef46a9e7ccad0fca1626299f48c2b3e/d0f75/thumbsupnews_contact.jpg 750w", "/static/6ef46a9e7ccad0fca1626299f48c2b3e/0a251/thumbsupnews_contact.jpg 1000w", "/static/6ef46a9e7ccad0fca1626299f48c2b3e/03ffe/thumbsupnews_contact.jpg 1200w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Thumbsupnews Home Page List view
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/668ff3b2206729add8a81d2dbc4d6a4b/03ffe/thumbsupnews_list.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.8%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEBf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAAB2nkbSgQj/8QAGBAAAgMAAAAAAAAAAAAAAAAAABIBESH/2gAIAQEAAQUCYvLG1pIP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Bh//EABYQAQEBAAAAAAAAAAAAAAAAADEQIP/aAAgBAQAGPwIhj//EABoQAAMBAAMAAAAAAAAAAAAAAAABESGBkeH/2gAIAQEAAT8hpeytzgTMNqxdClMHqP/aAAwDAQACAAMAAAAQOC//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAVEQEBAAAAAAAAAAAAAAAAAAAAAf/aAAgBAgEBPxAsf//EABsQAQACAwEBAAAAAAAAAAAAAAEAESExUUFh/9oACAEBAAE/ELyA3Iyef1DFaZ7Dh6viMsw5UdbDfJ//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Thumbsupnews Home Page List view",
            "title": "Thumbsupnews Home Page List view",
            "src": "/static/668ff3b2206729add8a81d2dbc4d6a4b/953fe/thumbsupnews_list.jpg",
            "srcSet": ["/static/668ff3b2206729add8a81d2dbc4d6a4b/20b44/thumbsupnews_list.jpg 125w", "/static/668ff3b2206729add8a81d2dbc4d6a4b/bce2d/thumbsupnews_list.jpg 250w", "/static/668ff3b2206729add8a81d2dbc4d6a4b/953fe/thumbsupnews_list.jpg 500w", "/static/668ff3b2206729add8a81d2dbc4d6a4b/d0f75/thumbsupnews_list.jpg 750w", "/static/668ff3b2206729add8a81d2dbc4d6a4b/0a251/thumbsupnews_list.jpg 1000w", "/static/668ff3b2206729add8a81d2dbc4d6a4b/03ffe/thumbsupnews_list.jpg 1200w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      